<template> 
    <div class='dp'>
      <div class='d-none d-lg-flex'>
        <div class='dp-container'> 
      <div class='left-column'> 
        <div id="slide-wrapper" >
				  <div id="slider">
            <img class="thumbnail active" :src="'https:' + mainImage">
<!--             <div v-for='imageURL in images' :key='imageURL.id'> 
              <img class="thumbnail" :src="'https:' + imageURL">
            </div> -->
				  </div>
			  </div>
        <div class='image-box'>
          <img 
          :src="'https:' + mainImage"
          :width='width'
          id='featured'
          class='featured zoom'
        >
        </div>
      </div>
      
      
      <div class='product-info-column'>
        <div class='header'>
          <h1 class='text-h2 title'> {{collectionName}} </h1>
        </div>
         <div class="varients mt-5"></div>
         
        
            <div v-for='product in products' :key='product.id' class="mt-6">
                <div class='d-flex cart ' >
                    <v-card 
                    @click="handleClick(product)"
                    class="mx-auto mb-2 cart-card cart"
                    :width="cardWidth"
                    tile
                    elevation='0'
                    >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-md-h5 text-h7  mb-1"> {{product.name}}</v-list-item-title>
                                <v-list-item-content v-if='product.oldPrice == 0' class='text-subtitle-1'> <span>Please Call for Price</span> </v-list-item-content>
                                <span class='price text-body ml-2 text-h6 text-subtitle-1' v-if='$auth.isAuthenticated && product.oldPrice != 0'> Price: {{formatPrice(product.oldPrice)}} </span>
                                <span class='price text-body ml-2 text-h6 text-subtitle-1' v-if='!$auth.isAuthenticated && product.oldPrice != 0'> Price: {{formatPrice(product.msrp)}} </span>
                            </v-list-item-content>
                            
                            <img height='100'
                                width='150'
                                :src="'http:' + product.mainImage"
                                class="mt-5">
                        </v-list-item>
                        <v-card-actions>
                            
                            <div class='action-field'>
                                
                            </div>
                        </v-card-actions>
                    </v-card>
                    
                </div>
                
            </div>

      </div>


      </div>
      </div>
      <!-- <div class='d-lg-none justify-center'>
        <div class='dp-container-mobile'> 
        <div class='left-column-mobile'> 
          
          <div class='image-box'>
            <img 
            :src="'https:' + mainImage"
            :width='width'
            id='featured-mobile'
            class='featured zoom'
          >
          </div>
          <div id="slide-wrapper" >
            <div id="slider-mobile">
              <img class="thumbnail thumbnail-mobile active " :src="'https:' + mainImage">
              <div v-for='imageURL in images' :key='imageURL.id'> 
                <img class="thumbnail thumbnail-mobile" :src="'https:' + imageURL">
              </div>
            </div>
          </div>
        </div> -->
        
        <!-- <div class='product-info-column'>
          <div class='header'>
            <h1 class='text-h2'> {{product.name}} </h1>
          </div>
          <div class='description'>
            <h1 class='text-h4 mt-6'> About this item </h1>
            <p class='text-subtitle-1 ml-2'> {{product.description}}</p>
          </div>
          <div class='varients'>
          
              <v-select
                :items="varients"
                class='ml-2 varient-selector'
                filled
                v-model='activeVarient'
                label="Product Style"
                v-if='varients.length !== 0'
                id='varientName'
            
          ></v-select>
          </div>
          <div class='stock-info d-flex  pa-2 text-subtitle-1 mt-4'>
            <p v-if="product.stock > 0 && product.stock <= 5" class='stock-warning'> Low Stock </p>
            <p v-if='product.stock === 0' class='stock-warning ml-4'> Out of Stock </p>
            <p v-if='product.stock == 500' class='stock-warning ml-4'> Special Order</p>
            <p v-if='product.stock > 5 && product.stock != 500' class='stock-warning ml-4'> In Stock </p>
            <p v-if="product.restockDate != null" class='ml-4 stock-warning'> Restock Date: <span class='date'>{{product.restockDate}}</span> </p>

          </div>
        


        </div> -->


        </div>
</template>

<script> 


import mediumZoom from 'medium-zoom'
    export default {
        name: 'CollectionDetailsPanel',
        async created () {
            //await this.sleep(100)
            //this.normalPrice = this.product.price
            //this.normalStock = this.product.stock
            //this.normalDate = this.product.restockDate
            //await this.imageSelector()
            //await this.getVarients()
            
            

            mediumZoom('.zoom', {
              margin: 50
            })
            
            
        },
        data () {
          return {
            varients: [],
            activeVarient: '',
            normalPrice: '',
            normalStock: '',
            quantity: 1,
            mainImage: this.$store.getters.getImage,
            collectionName: this.$store.getters.GetActiveCollectionTitle,
            products: this.$store.getters.GetResults,
          }
        },
        computed: {
          width () {
              switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                case 'sm': return '100%'
                case 'md': return '400px'
                case 'lg': return '50%'
                case 'xl': return '50%'
              }
            },
            cardWidth () {
                switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                case 'sm': return '100%'
                case 'md': return '560'
                case 'lg': return '600'
                case 'xl': return '100%'
                }
            },
          getProductPrice () {

            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            })

            if(this.product.price === "Please Call for Price"){
              return "Please Call for Price"
            }

            else if(this.product.price == 0 || this.product.price == 0.00){
              return "Please call for Price"
            }

            else if(this.$auth.isAuthenticated) {
              return formatter.format(this.product.price)
            }

            else {
              return formatter.format(this.product.msrp)
            }
          }     
        },
        props: [''],
        methods: {


            formatPrice(price) {
              const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        })
              return formatter.format(price)
            },

            handleClick(product){
                let url = "/products/" + product.productSlug
                this.$store.commit('addTargetProduct', product)
                //navigateToRoute('/product/')
                window.location.href = url
            },
          imageSelector() {
            let thumbnails = document.getElementsByClassName('thumbnail')
            let activeImages = document.getElementsByClassName('active')
            
            for (var i = 0; i < thumbnails.length; i++){
              thumbnails[i].addEventListener('click', function(){
                if(activeImages.length > 0){
                  activeImages[0].classList.remove('active')
                }
                this.classList.add('active')
                document.getElementById('featured').src = this.src
                document.getElementById('featured-mobile').src = this.src
              })
            }
          },
          sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
          },
          getVarients(){
            this.product.varients.forEach(v => {
              this.varients.push(v.varientName)
            })
            if(!!this.varients){
              this.activeVarient = this.varients[0]
            }
          },
          async AddToCart(product){
            var id = Math.random().toString(16).slice(2)
            let cartItem = {
              deleteId: id,
              id: product.id,
              name: product.name,
              price: product.price,
              varientName: null,
              quantity: this.quantity,
              cbm: product.cbm,
              weight: product.weight,
              imageURL: product.mainImage,
            }
            cartItem.varientName = this.activeVarient
            this.$store.commit('addItemToCart', cartItem)
            
          }
        
        },
        
        watch: {
          activeVarient: function(val) {
            this.product.varients.forEach(v => {
              if(this.activeVarient === v.varientName){
                if(typeof(v.varientPrice) !== 'undefined' && v.varientPrice !== this.product.price){
                  this.product.price = v.varientPrice
                }
                else {
                  this.product.price = this.normalPrice
                }
                

                if(typeof(v.stock) !== 'undefined' && v.stock !== this.product.stock) {
                  this.product.stock = v.stock
                  this.product.restockDate = null

                  if(v.restockDate !== undefined){
                      let date = v.restockDate.split('-')
                      let dateStr = date[1] + "/" + date[2] + "/" + date[0]
                      this.product.restockDate = dateStr
                  }
                  
                
                }
                else {
                    this.product.stock = this.normalStock
                    this.product.restockDate = this.restockDate
                  }
              }
            })
          },

        }
    }
</script>

<style scoped> 
.dp {
  margin: 0 auto;
  width: 90%;

  display: flex;
  height: 100%;
  overflow-wrap: normal;
}
.title {
    min-width: 500px;
}
.dp-container {
  margin-top: 20px;
  padding-top: 0;
  width:100%;
  margin: 0 auto;
  display: flex;
  background-color: white;
}

.dp-container-mobile {
  margin-top: 20px;
  padding-top: 0;
  width:100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

#slider-mobile {
  display: flex;
}
.cart {
    background-color: #E8E8E8;
}

.thumbnail{
	object-fit: cover;
	width: 100px;
	height: 60px;
	cursor: pointer;
	opacity: 0.5;
	margin: 5px;
	border: 2px solid black;
}
.thumbnail-mobile{
	object-fit: cover;
	width: 50px;
	height: 60px;
	cursor: pointer;
	opacity: 0.5;
	margin: 5px;
	border: 2px solid black;
}

.left-column {
  display: flex;
  max-width: 60%;
  min-width: 55%;
}
.left-column-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.image-box {
  display: absolute;
  width: 100%;
}

.featured{
  min-height: 250px;
  max-height: 550px;
  max-width: 650px;
  width: 100%;
  object-fit: contain;
  object-position: left;
}



.product-info-column {
  padding: 10px 10px 10px 10px;
  margin-left: 2vw;
}

.varients {
  border-bottom: 1px solid black;
  width: 100%;
}
.price {
  color: grey;
}

.varient-selector{
  max-width: 50%;
}

.table {
  width: 50%;
}
td {
  width: 50%;

}
.text-field {
  text-align: center;
  display: flex;
  align-items: center;
}

tr {
  display: flex;
  text-align: center;
  justify-content: center;
}
.left-row {
 
  border-top: solid 1px rgba(31, 30, 30, 0.37);
  background-color: rgba(224, 236, 190, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.right-row {
  
  border-top: solid 1px rgba(31, 30, 30, 0.37);
}

.stock-warning {
  color: red;
}
.date {
  color: black;
}

.table-head {
  margin-bottom: 5vh;
}

.qty-field {
  width: 80px;
}
  .price-text {
        color:rgb(109, 108, 108);
    }
</style>